import { notification } from "antd";
import axios from "axios";
import jwt_decode from "jwt-decode";
import type { PropsWithChildren } from "react";
import React, {
  createContext as originCreateContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useGoogleLogin } from "@react-oauth/google";
import dayjs from "dayjs";
import useMySWR from "@/hooks/useMySWR";
import { signatureKey } from "@/constant/types";

function createContext<T>(): [React.Provider<T>, () => T, React.Context<T>] {
  const context = originCreateContext<T>({} as T);

  return [context.Provider, () => useContext(context), context];
}

export function isExpired(expiredTime: number) {
  return !dayjs(expiredTime * 1000)
    .subtract(5, "minutes")
    .isAfter(dayjs());
}

interface ProfileIF {
  avatar?: string;
  display_name?: string;
  bio?: string;
}

interface AuthContext {
  handleLoginGoogle: () => void;
  logout: () => void;
  user: UserInfo | undefined | null;
  token: TokenBlob | undefined | null;
  setToken: React.Dispatch<React.SetStateAction<TokenBlob | null | undefined>>;
  mutateUser: () => any;
  isLoadingGetProfile: boolean;
  updateProfileInformation?: (info: ProfileIF) => any;
}

export interface TokenBlob {
  exp?: number;
  isNoRef?: boolean;
  accessToken?: string;
}

export interface IMarketingInformation {
  sector: string;
  marketing_style: string;
  color_style: string;
}

export interface ICompanyInformation {
  address: string;
  google_review: string;
  postcode: string;
  trip_advisor: string;
  google_map: string;
  color_style: string;
}

export interface IGeneralInformation {
  gender: string;
  date_of_birth: string;
  phone_number: string;
}

export interface UserInfo {
  username: string;
  email: string;
  avatar: string;
  general_information: IGeneralInformation;
  marketing_information: IMarketingInformation;
  company_information: ICompanyInformation;
  status: string;
  role: string;
  created_at: string;
  updated_at: string;
  id: string;
  data_id?: { title: string };
}

const [Provider, useAuthContext] = createContext<AuthContext>();

export const getStoredSignature = (): TokenBlob | undefined => {
  try {
    const data = JSON.parse(window.localStorage.getItem(signatureKey) || "{}");
    if (isExpired(data.exp || 0)) {
      return undefined;
    }
    return data;
  } catch {
    return undefined;
  }
};

export const AProvider = ({ children }: PropsWithChildren) => {
  const [ipAddress, setIPAddress] = useState<string>("");
  const [token, setToken] = useState<TokenBlob | undefined | null>(null);
  const {
    data: userData,
    mutate: mutateUser,
    isLoading: isLoadingGetProfile,
  } = useMySWR<{ data: UserInfo }>(token ? [`/v1/user/details`, token] : null);
  const user = useMemo(() => userData?.data, [userData]);

  useLayoutEffect(() => {
    // Get Token
    const token = getStoredSignature();
    setToken(token);
    // Get Ip Address
    fetch("https://api.ipify.org?format=json")
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error));
  }, []);

  const logout = useCallback(() => {
    window.localStorage.setItem(signatureKey, "");
    setToken(undefined);
    notification.success({
      duration: 10000,
      message: "Log-out",
      description: "Log-out successful!",
    });
  }, []);

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: response => {
      axios
        .post(`/v1/auth/social_login`, {
          token: response.access_token,
          ip_address: ipAddress,
        })
        .then(response => {
          const result = response.data.data;
          if (result) {
            const data = jwt_decode(result.accessToken) as any;
            // Sign success
            const token = {
              accessToken: result.accessToken,
              exp: data.exp,
            } as TokenBlob;
            setToken(token);
            window.localStorage.setItem(signatureKey, JSON.stringify(token));
          }
          notification.success({
            duration: 10000,
            message: "Log-in",
            description: "Google signIn successful!",
          });
        })
        .catch(() => {
          notification.error({
            duration: 10000,
            message: "Log-in - Failed",
            description: "Google signIn fail! Please try again.",
          });
        });
    },
    onError: () => {
      notification.error({
        duration: 10000,
        message: "Log-in - Failed",
        description: "Google signIn fail! Please try again.",
      });
    },
    flow: "implicit",
  });

  // const { trigger: updateProfile } = useMyMutation("/v1/user/profile-info");
  // const updateProfileInformation = useCallback(
  //   (info: ProfileIF) => {
  //     updateProfile(info)
  //       .then(() => mutateUser())
  //       .then(() => message.success(t("Updated Profile Information")))
  //       .catch(e =>
  //         notification.error({
  //           message: t("Update profile info error!"),
  //           description: e?.response?.data?.message || t("There are error during request action!"),
  //         }),
  //       );
  //   },
  //   [mutateUser, t, updateProfile],
  // );
  return (
    <Provider
      value={{
        logout,
        handleLoginGoogle,
        user,
        token,
        isLoadingGetProfile,
        setToken,
        mutateUser,
      }}
    >
      {children}
    </Provider>
  );
};

export { useAuthContext };

export const AuthProvider = ({ children }: PropsWithChildren) => <AProvider>{children}</AProvider>;
