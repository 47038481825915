import { signatureKey } from "@/constant/types";
import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";
axios.defaults.baseURL = process.env.NEXT_PUBLIC_APP_API || "";
axios.interceptors.request.use(function (config) {
  const saveData = JSON.parse(window.localStorage.getItem(signatureKey) || "{}");
  if (saveData?.accessToken && config.headers && config.url) {
    config.headers["Authorization"] = `Bearer ${saveData.accessToken}`;
  }
  return config;
});

const defaultInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_API,
  headers: {
    "Content-Type": "application/json, charset=utf-8",
  },
});

defaultInstance.interceptors.request.use(function (config) {
  const saveData = JSON.parse(window.localStorage.getItem(signatureKey) || "{}");
  if (saveData?.accessToken && config.headers && config.url) {
    config.headers["Authorization"] = `Bearer ${saveData.accessToken}`;
  }
  return config;
});

const cloudfrontAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CLOUDFRONT_API,
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      window.localStorage.setItem(signatureKey, "");
    } else {
      return Promise.reject(error);
    }
  },
);

export { defaultInstance as axiosClient, cloudfrontAxios };
