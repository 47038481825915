"use client";

import { AuthProvider } from "@/context/authContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import DefaultLayout from "./DefaultLayout";

const ContextProvider = ({ children }: { children: React.ReactNode }) => (
  <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? ""}>
    <AuthProvider>
      <DefaultLayout>{children}</DefaultLayout>
    </AuthProvider>
  </GoogleOAuthProvider>
);

export default ContextProvider;
