"use client";

import { useEffect, useMemo, useState } from "react";
import determineDisplaySize from "../utils/determineDisplaySize";

const isBrowser = () => typeof window !== "undefined";

const useResponsive = () => {
  const [currentDisplaySize, setCurrentDisplaySize] = useState(() =>
    isBrowser() ? determineDisplaySize(window.innerWidth) : 0,
  );

  useEffect(() => {
    if (isBrowser()) {
      const handler = () => setCurrentDisplaySize(determineDisplaySize(window.innerWidth));
      window.addEventListener("resize", handler);
      return () => window.removeEventListener("resize", handler);
    }
  }, []);

  return useMemo(() => currentDisplaySize, [currentDisplaySize]);
};

export default useResponsive;
