"use client";

import type { Key, SWRConfiguration } from "swr";
import useSWR from "swr";
import { useMemo } from "react";
import { axiosClient } from "@/lib/axiosClient";

type Methods = "get" | "post";

type MySWRConfiguration<T, E> = SWRConfiguration<T, E> & {
  method?: Methods;
  dataOrParams?: any;
};

export default function useMySWR<T = any, E = any>(
  keys: Key,
  methodOrConfig?: Methods | MySWRConfiguration<T, E>,
  dataOrParams?: any,
  config?: SWRConfiguration<T, E>,
  client: typeof axiosClient = axiosClient,
) {
  const method = useMemo<string>(() => {
    if (!methodOrConfig || (typeof methodOrConfig === "object" && !methodOrConfig.method)) {
      return "get";
    }

    if (typeof methodOrConfig === "string") {
      return methodOrConfig;
    } else {
      return methodOrConfig.method || "get";
    }
  }, [methodOrConfig]);

  const dataOrParamsRequest = useMemo(
    () => dataOrParams || (typeof methodOrConfig === "object" ? methodOrConfig["dataOrParams"] : undefined),
    [dataOrParams, methodOrConfig],
  );

  return useSWR<T, E>(
    keys,
    (args: any) => {
      const url = typeof args === "string" ? args : args[0];
      return (
        method === "get"
          ? client.get<T>(url, { params: dataOrParamsRequest })
          : client.post<T>(url, dataOrParamsRequest)
      ).then(res => res.data);
    },
    {
      revalidateOnFocus: false,
      dedupingInterval: 3000,
      ...(config || (typeof methodOrConfig === "object" ? methodOrConfig : undefined)),
    },
  );
}
