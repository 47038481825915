"use client";
import useResponsive from "@/app/hooks/useResponsive";
import DisplaySize from "@/app/models/DisplaySize";
import { useAuthContext } from "@/context/authContext";
import { CaretLeftOutlined, CaretRightOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  faCircleQuestion,
  faCircleUser,
  faGear,
  faHome,
  faRightToBracket,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Layout, Space, theme, Typography } from "antd";
import axios from "axios";
import classNames from "classnames";
import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import React, { useLayoutEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import type { IOption } from "../models/Compate";

const { Header, Content, Sider } = Layout;

const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  const pathname = usePathname();
  const displaySize = useResponsive();
  const { token, handleLoginGoogle, logout } = useAuthContext();
  const router = useRouter();

  const DATE = `${dayjs().format("h:mm A")}, ${dayjs().format("D	MMMM")}, ${dayjs().format("dddd")}`;

  useLayoutEffect(() => {
    if (displaySize <= DisplaySize.Tablet) {
      setCollapsed(true);
    }
  }, [displaySize]);

  const fetchData = async (value = "", limit = 10) =>
    axios.get("v1/sentiment/get-list-data", {
      params: {
        query: value,
        limit,
      },
    });

  const loadOptions = (inputValue: string, callback: (options: IOption[]) => void) => {
    fetchData(inputValue, 10).then(res => {
      callback((res?.data?.data ?? []).map((i: { title: any; _id: any }) => ({ label: i.title, value: i._id })));
    });
  };

  return (
    <Layout hasSider>
      <Sider
        className={classNames("min-h-screen", { "max-w-[256px] w-[256px] ": !collapsed })}
        theme="light"
        collapsed={collapsed}
        style={{ overflow: "auto", position: "fixed", left: 0, top: 0, bottom: 0 }}
      >
        <Space className="w-full pl-6 py-7 justify-between ">
          <Image width={152} height={45} src={"/logo.svg"} alt="logo" />
          <div
            onClick={() => setCollapsed(prev => !prev)}
            className={classNames(
              "w-[25px] h-[49px] bg-stroke justify-center items-center rounded-tl-2xl rounded-bl-2xl cursor-pointer md:flex hidden",
            )}
          >
            {!collapsed ? (
              <CaretLeftOutlined className="text-[#A0AEC0]" width={6} height={11} />
            ) : (
              <CaretRightOutlined className="text-[#A0AEC0]" width={6} height={11} />
            )}
          </div>
        </Space>
        <div className="flex flex-col justify-between" style={{ height: "calc(100vh - 105px)" }}>
          <ul className="flex flex-col navbar pb-6">
            {[
              {
                label: "Home",
                key: "/",
                icon: faHome,
                isShow: true,
              },
              {
                label: "Content Generation",
                key: "/content-generation",
                icon: faRobot,
                isShow: true,
              },
              {
                label: "Virtual Agent",
                key: "/virtual-agent",
                icon: faRobot,
                isShow: true,
              },
              {
                label: "Tool",
                key: "/tool",
                icon: faGear,
                isShow: true,
                disable: true,
              },
              {
                label: "Profile",
                key: "/profile",
                icon: faCircleUser,
                isShow: !!token?.accessToken,
              },
            ].map(menu =>
              menu.isShow ? (
                <Link
                  href={menu.key}
                  key={menu.key}
                  className={classNames(
                    "navbar-item",
                    { "bg-[#EEF2FF]": menu.key === pathname },
                    { "pointer-events-none opacity-50": menu.disable },
                  )}
                  aria-disabled={menu.disable}
                >
                  {menu.key === pathname && !collapsed && (
                    <div className="h-[50px] w-2 rounded-tr-[60px] rounded-br-[60px] bg-main-1 transition-all" />
                  )}
                  <FontAwesomeIcon
                    className={classNames(
                      "w-[18px] h-[18px] text-black-1",
                      { "text-main-1": menu.key === pathname },
                      { "pl-4": menu.key === pathname },
                      { "pl-6": menu.key !== pathname || collapsed },
                    )}
                    icon={menu.icon}
                  />
                  <Typography.Text
                    className={classNames("text-base font-medium leading-5", {
                      "hide-text": collapsed,
                      "text-main-1": menu.key === pathname,
                    })}
                  >
                    {menu.label}
                  </Typography.Text>
                </Link>
              ) : null,
            )}
          </ul>
          {collapsed ? (
            <>
              <div className="flex flex-col items-center mb-3 gap-3">
                {!token?.accessToken && (
                  <Button
                    onClick={() => handleLoginGoogle()}
                    size="large"
                    className="bg-main-1 h-[57px] mb-3 text-white"
                  >
                    <FontAwesomeIcon className={classNames("w-[18px] h-[18px] text-white")} icon={faRightToBracket} />
                  </Button>
                )}
                <Button size="large" className="bg-[#FFFBEF] h-[56px] mb-3 text-white outline-none border-none">
                  <FontAwesomeIcon className={classNames("w-[18px] h-[18px] text-[#FFBB38]")} icon={faCircleQuestion} />
                </Button>
              </div>
            </>
          ) : (
            <div>
              {!token?.accessToken && (
                <div className="flex flex-col items-center justify-end mx-4 w-[224px] h-[293px] rounded-[30px] bg-[#EBEEFF] relative mb-3">
                  <Image
                    className="absolute left-1/2 -translate-x-1/2 -top-6"
                    width={164}
                    height={174}
                    src={"/navbar/illus.svg"}
                    alt="illus"
                  />
                  <Typography.Text className="font-medium text-base w-[151px] pb-2 text-center">
                    Login for more features.
                  </Typography.Text>
                  <Button
                    onClick={() => handleLoginGoogle()}
                    size="large"
                    className="bg-main-1 w-[200px] h-[57px] mb-3 text-white rounded-[15px]"
                  >
                    Login
                  </Button>
                </div>
              )}
              <div className="flex justify-center">
                <Button
                  size="large"
                  className="bg-[#FFFBEF] w-[224px] h-[56px] mb-3 text-white rounded-2xl outline-none border-none"
                >
                  <FontAwesomeIcon className={classNames("w-[18px] h-[18px] text-[#FFBB38]")} icon={faCircleQuestion} />
                  <Typography.Text className="font-medium text-base text-[#FFBB38] text-center pl-2">
                    Help
                  </Typography.Text>
                </Button>
              </div>
            </div>
          )}
        </div>
      </Sider>
      <Layout className={classNames("bg-gray-2", { "ml-[256px]": !collapsed }, { "ml-[80px]": collapsed })}>
        <Header className="mx-6 h-[82px] rounded-b-3xl" style={{ padding: 0, background: colorBgContainer }}>
          <div className={"flex w-full items-center px-6 py-4 justify-center md:justify-between"}>
            <div className="flex-col hidden md:flex">
              <Typography.Text className="text-base text-[#B1B1B1] font-medium">Welcome!</Typography.Text>
              <Typography.Text className="text-base text-[#B1B1B1] font-medium">
                It&apos;s <Typography.Text className="text-[#29108B]">{DATE}</Typography.Text>
              </Typography.Text>
            </div>
            <div className="flex w-full md:w-auto items-center gap-7">
              <div className="w-full lg:w-[410px] flex items-center relative">
                <AsyncSelect
                  placeholder={"Search for everything"}
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions
                  className="search-header w-full md:w-[410px]"
                  onChange={option => router.push(`/data/${option?.value}`)}
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      border: "none",
                      borderStyle: "none",
                      height: "48px",
                      background: "#F5F7FA",
                      borderRadius: "16px",
                    }),
                    placeholder: styles => ({ ...styles }),
                    indicatorsContainer: styles => ({ ...styles, display: "none" }),
                    container: styles => ({ ...styles, width: "100%" }),
                  }}
                />
                <Button
                  className="absolute top-1/2 -translate-y-1/2 right-12 z-40 flex items-center justify-center w-6 h-6 rounded-lg border border-solid border-stroke p-0"
                  icon={<Image className="object-cover" width={11} height={11} src={"/navbar/x-icon.svg"} alt="x" />}
                />
                <Button
                  className="absolute top-1/2 -translate-y-1/2 right-4 z-40 flex items-center justify-center  w-6 h-6 rounded-lg border border-solid border-stroke p-0"
                  icon={<Image className="object-cover" width={10} height={18} src={"/navbar/k-icon.svg"} alt="x" />}
                />
              </div>
              {token?.accessToken && (
                <Button onClick={logout} title={"Logout"} size={"small"} icon={<LogoutOutlined />} />
              )}
            </div>
          </div>
        </Header>

        <Content style={{ margin: "24px", overflow: "initial" }}>
          <div style={{ minHeight: "calc(100vh - 130px)" }}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
